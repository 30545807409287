import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from "@fortawesome/free-brands-svg-icons"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faBehance } from "@fortawesome/free-brands-svg-icons"
import { faPinterestP } from "@fortawesome/free-brands-svg-icons"

const SocialWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SocialLink = styled.a`
  color: ${({theme}) => theme.colors.black};
  margin-bottom: 4.2rem;
  font-size: 1.4rem;
  line-height: 0.96rem;
  font-weight: 400;
  transition: .3s ease;
  &:nth-last-child(1){
    margin-bottom: 0;
  }
  
  &:hover{
    color: ${({theme}) => theme.colors.main};
  }
`;

const SocialMedia = ({className}) => (
    <SocialWrap className={className}>
        <SocialLink href="https://www.facebook.com/agencjamakadu" target="_blank">
            <FontAwesomeIcon icon={faFacebookF}/>
        </SocialLink>
        <SocialLink href="https://www.instagram.com/agencjamakadu/" target="_blank">
            <FontAwesomeIcon icon={faInstagram}/>
        </SocialLink>
        <SocialLink href="https://twitter.com/Makadu_pl" target="_blank">
            <FontAwesomeIcon icon={faTwitter}/>
        </SocialLink>
        <SocialLink href="https://www.behance.net/makadu" target="_blank">
            <FontAwesomeIcon icon={faBehance}/>
        </SocialLink>
        <SocialLink href="https://pl.pinterest.com/agencjamakadu/" target="_blank">
            <FontAwesomeIcon icon={faPinterestP}/>
        </SocialLink>
    </SocialWrap>
);

SocialMedia.propTypes = {
    className: PropTypes.string
};

SocialMedia.defaultProps = {
    className: ''
};

export default SocialMedia;