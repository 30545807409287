import React from 'react';
import styled from 'styled-components/macro';
import Img from 'gatsby-image';
import Block from '../shared/wraps/Block/Block';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, A11y } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import useSlidesPerView from '../../hooks/useSlidesPerView';
import FadeLink from '../shared/navigations/FadeLink/FadeLink';

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
`;

const StyledFadeLink = styled(FadeLink)`
  height: 100%;
`;

SwiperCore.use([Navigation, A11y]);

const Slider = props => {
  const {
    slides: {
      allDirectusProjekty: { nodes: allSlides },
    },
  } = props;

  const slidesPerView = useSlidesPerView();

  return (
    <Wrapper>
      <Swiper
        slidesPerView={slidesPerView}
        navigation={{ nextEl: '#sliderNext', prevEl: '#sliderPrev' }}
        loop
      >
        {allSlides.map(slide => {
          return (
            <SwiperSlide key={slide.title}>
              <Block height="38rem">
                <StyledFadeLink to={`/projekty/${slide.slug}`}>
                  <StyledImg
                    fluid={slide.preview.localFile.childImageSharp.fluid}
                    alt={slide.title}
                  />
                </StyledFadeLink>
              </Block>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Wrapper>
  );
};

export default Slider;
