import React from 'react';
import SiteBackground from '../../../shared/SiteBackground/SiteBackground';
import Section from '../../../shared/wraps/Section/Section';
import Block from '../../../shared/wraps/Block/Block';
import GridBlock from '../../../shared/wraps/GridBlock/GridBlock';
import SocialMedia from '../../../SocialMedia/SocialMedia';
import PaginationArrows from '../../../shared/navigations/Pagination/PaginationArrows';
import BigTitle from '../../../shared/text/BigTitle';
import ColorBox from '../../../shared/wraps/ColorBox/ColorBox';
import Slider from '../../../Slider/Slider';
import styled from 'styled-components/macro';
import ParagraphShort from '../../../shared/text/ParagraphShort';
import { graphql, useStaticQuery } from 'gatsby';
import FadeLink from '../../../shared/navigations/FadeLink/FadeLink';

const StyledSection = styled.div`
  .socialBlock {
    grid-row: 1/3;
    grid-column: 1/2;
  }

  .titleBlock {
    grid-row: 1/2;
    grid-column: 2/4;
  }

  .portfolioBtnBlock {
    grid-row: 1/2;
    grid-column: 4/5;
  }

  .portfolioSliderBlock {
    grid-row: 2/3;
    grid-column: 2/5;
  }

  @media screen and (max-width: 991px) {
    .socialBlock {
      display: none;
    }

    .titleBlock {
      grid-row: 1/2;
      grid-column: 1/3;
    }

    .portfolioBtnBlock {
      grid-row: 1/2;
      grid-column: 3/4;
    }

    .portfolioSliderBlock {
      grid-row: 2/3;
      grid-column: 1/4;
    }
  }

  @media screen and (max-width: 767px) {
    .socialBlock {
      display: flex;
      order: 3;
    }
  }
`;

const StyledBigTitle = styled(BigTitle)`
  @media screen and (max-width: 991px) {
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 767px) {
    margin-top: 32px;
    margin-bottom: 27px;
  }
`;

const StyledSocialMedia = styled(SocialMedia)`
  margin-bottom: 5rem;
  @media screen and (max-width: 991px) {
    display: none;
  }
`;

const StyledPaginationArrows = styled(PaginationArrows)`
  @media screen and (max-width: 991px) {
    display: none;
  }
  @media screen and (max-width: 767px) {
    display: flex;
  }
`;

const StyledParagraph = styled(ParagraphShort)`
  margin-bottom: 24.4rem;
  transform: translateY(50%);
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const ParagraphMobile = styled(ParagraphShort)`
  display: none;
  @media screen and (max-width: 991px) {
    display: block;
    text-align: center;
    font-size: 18px;
    padding: 0 5rem;
    margin-bottom: 5rem;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 46px;
  }
`;

const ParagraphPortfolio = styled.p`
  font-size: 1.6rem;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  padding: 0;
  font-weight: 400;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 30px;
  }
`;

const StyledFadeLink = styled(FadeLink)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  text-decoration: none;
`;

const StyledColorBox = styled(ColorBox)`
  position: relative;
`;

const portfolioQuery = graphql`
  {
    allDirectusProjekty(filter: { main_page: { eq: true } }) {
      nodes {
        title
        slug
        preview {
          localFile {
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 600, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;

const Header = () => {
  const data = useStaticQuery(portfolioQuery);

  return (
    <StyledSection>
      <Section first>
        <SiteBackground />
        <GridBlock className="socialBlock">
          <Block padding>
            <StyledSocialMedia />
            <StyledParagraph>
              Zajmujemy się kompleksowym projektowaniem systemów i stron
              internetowych.
            </StyledParagraph>
          </Block>
          <StyledPaginationArrows prevId="sliderPrev" nextId="sliderNext" />
        </GridBlock>
        <GridBlock className="titleBlock">
          <StyledBigTitle mb="6.4rem">strony www</StyledBigTitle>
          <ParagraphMobile>
            Zajmujemy się kompleksowym projektowanie systemów i stron
            internetowych.
          </ParagraphMobile>
        </GridBlock>
        <GridBlock className="portfolioBtnBlock">
          <Block padding>
            <StyledParagraph>Sprawdź nasze projekty.</StyledParagraph>
          </Block>
          <StyledColorBox>
            <StyledFadeLink to="/projekty/">
              <ParagraphPortfolio>Portfolio</ParagraphPortfolio>
            </StyledFadeLink>
          </StyledColorBox>
        </GridBlock>
        <GridBlock className="portfolioSliderBlock">
          <Slider slides={data} />
        </GridBlock>
      </Section>
    </StyledSection>
  );
};
export default Header;
