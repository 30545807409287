import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ParagraphShort from '../shared/text/ParagraphShort';
import diImg from '../../assets/images/clients/di.png';
import charlieImg from '../../assets/images/clients/charlie.png';
import proArtImg from '../../assets/images/clients/pro_art.png';
import solnyImg from '../../assets/images/clients/solny_invest.png';
import stxImg from '../../assets/images/clients/stx.jpg';
import werandaImg from '../../assets/images/clients/weranda.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, A11y } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import useSlidesPerView from '../../hooks/useSlidesPerView';

const SwiperWrap = styled.div`
  width: 100%;
  overflow: hidden;
  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5rem 6.5rem 4.5rem 5.5rem;
    background-color: #fff;
    border-left: 1px solid ${({ theme }) => theme.colors.gray4};
    border-right: 1px solid ${({ theme }) => theme.colors.gray4};
    height: auto;
  }
`;

const Quote = styled(ParagraphShort)`
  position: relative;
  padding-bottom: 3.8rem;
  margin-bottom: 0;
`;

const Logo = styled.img`
  width: 9rem;
`;

SwiperCore.use([Navigation, A11y]);

const SliderReferences = props => {
  const { className } = props;
  const slidesPerView = useSlidesPerView();

  return (
    <SwiperWrap className={className}>
      <Swiper
        slidesPerView={slidesPerView}
        navigation={{
          prevEl: '#sliderPrevReferences',
          nextEl: '#sliderNextReferences',
        }}
        loop
      >
        <SwiperSlide>
          <Quote>
            “Polecam Agencję Makadu jako partnera z ciekawymi pomysłami, który
            rzetelnie podchodzi do powierzonych projektów.”{' '}
          </Quote>
          <Logo src={werandaImg} alt="Weranda Family" />
        </SwiperSlide>
        <SwiperSlide>
          <Quote>
            “Działania agencji charakteryzują się elastycznością, rzetelnością i
            kompleksowym podejściem do każdego z zadań.”{' '}
          </Quote>
          <Logo src={diImg} alt="Di Factory" />
        </SwiperSlide>
        <SwiperSlide>
          <Quote>
            “Na szczególne wyróżnienie zasługuje inicjatywa, gotowość do
            szybkiego działania i jakość usługi.”{' '}
          </Quote>
          <Logo src={stxImg} alt="STX Music Solutions" />
        </SwiperSlide>
        <SwiperSlide>
          <Quote>
            “Z całą odpowiedzialnością Solny Investment rekomenduje współpracę z
            Agencją Makadu.”{' '}
          </Quote>
          <Logo src={solnyImg} alt="Hotel Solny" />
        </SwiperSlide>
        <SwiperSlide>
          <Quote>
            “Zespół Makadu zrealizował dla nas kilka stron internetowych, które
            w całkowicie spełniły nasze oczekiwania.”{' '}
          </Quote>
          <Logo src={proArtImg} alt="PROART Maciej Szulc" />
        </SwiperSlide>
        <SwiperSlide>
          <Quote>
            “Programiści Makadu przygotowali od podstaw i obsługują technicznie
            nasz sklep internetowy - polecam jako specjalistów w swojej branży.”{' '}
          </Quote>
          <Logo src={charlieImg} alt="Och Charlie" />
        </SwiperSlide>
      </Swiper>
    </SwiperWrap>
  );
};

SliderReferences.propTypes = {
  className: PropTypes.string,
};

SliderReferences.defaultProps = {
  className: '',
};

export default SliderReferences;
