import React, { useContext } from 'react';
import Layout from '../layouts/Layout';
import SEO from '../components/seo';
import Header from '../components/Sites/Home/Header/Header';
import Make from '../components/Sites/Home/Make/Make';
import Clients from '../components/Sites/Home/Clients/Clients';
import styled from 'styled-components';
import { MainPageContext } from '../context/MainPageContextProvider';
import Intro from '../components/Sites/Home/Intro/Intro';

const StyledLayout = styled(Layout)`
  visibility: ${({ showIntro }) => (showIntro ? 'hidden' : 'visible')};
`;

const IndexPage = ({ location: { pathname: path } }) => {
  const { showIntro } = useContext(MainPageContext);
  return (
    <>
      <Intro />
      <StyledLayout
        showIntro={showIntro}
        className="layout"
        isLinkToTop={path === '/'}
        id="mainPage"
        path={path}
      >
        <SEO title="Makadu" />
        <Header />
        <Make />
        <Clients />
      </StyledLayout>
    </>
  );
};

export default IndexPage;
