import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import Section from '../../../shared/wraps/Section/Section';
import GridBlock from '../../../shared/wraps/GridBlock/GridBlock';
import BigTitle from '../../../shared/text/BigTitle';
import SiteBackground from '../../../shared/SiteBackground/SiteBackground';
import SliderReferences from '../../../Slider/SliderReferences';
import PaginationArrows from '../../../shared/navigations/Pagination/PaginationArrows';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { onScrollAnimation } from '../../../../animations/animations';

const StyledSection = styled(Section)`
  .sliderReferences {
    grid-row: 2/3;
    grid-column: 1/4;
  }

  .paginationArrows {
    grid-row: 2/3;
    grid-column: 4/5;
  }

  @media screen and (max-width: 991px) {
    .sliderReferences {
      grid-row: 2/3;
      grid-column: 1/4;
    }

    .paginationArrows {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    .paginationArrows {
      display: flex;
    }
  }
`;

const StyledBigTitle = styled(BigTitle)`
  margin-top: 14.5rem;
  margin-bottom: 16rem;
  grid-row: 1/2;
  grid-column: 2/4;

  @media screen and (max-width: 991px) {
    grid-column: 1/4;
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  @media screen and (max-width: 767px) {
    margin-top: 63px;
    margin-bottom: 28px;
  }
`;

const Clients = () => {
  const clientsWrapper = useRef(null);

  useEffect(() => {
    const section = clientsWrapper.current.base;
    const title = section.querySelector('.bigTitle');

    const runAnimation = () =>
      onScrollAnimation(title, 'clientsTitle', section, 'clientsSection');
    runAnimation();
    return () => {
      gsap.getById('clientsTitle').kill();
      ScrollTrigger.getById('clientsSection').kill();
    };
  });

  return (
    <StyledSection ref={clientsWrapper}>
      <SiteBackground bgcRight />
      <StyledBigTitle className="bigTitle">Klienci</StyledBigTitle>
      <SliderReferences className="sliderReferences" />
      <GridBlock className="paginationArrows">
        <PaginationArrows
          prevId="sliderPrevReferences"
          nextId="sliderNextReferences"
        />
      </GridBlock>
    </StyledSection>
  );
};

export default Clients;
