import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import ColorBox from '../../wraps/ColorBox/ColorBox';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.white};
  ${({ right }) =>
    right &&
    `
        transform: rotate(180deg);
    `}
`;

const StyledColorBox = styled(ColorBox)`
  cursor: pointer;
  &:hover {
    ${StyledFontAwesomeIcon} {
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

const Arrow = ({ right }) => (
  <StyledColorBox>
    <StyledFontAwesomeIcon icon={faChevronLeft} right={right ? 1 : 0} />
  </StyledColorBox>
);

Arrow.propTypes = {
  right: PropTypes.bool,
};

Arrow.defaultProps = {
  right: false,
};

export default Arrow;
